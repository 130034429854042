import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import MasterService from './../../services/MasterService';
import { toast } from 'react-toastify';
import Loader from '../common/loader';
// import secureStorage from '../../config/encrypt';
const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};
const MarkInactivePopup = (props) => {
    const [errors, setErrors] = useState({});
    const [errorList, setErrorList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userList, setUserList] = useState([]);
    const [ConfigList, setConfigList] = useState([])
    const [reason_for_inactive, setReasonForInactive] = useState(null)    
    const [inactive_reason_comment, setInactiveReasonComment] = useState('')

    useEffect(()=>{
        getConfigList()
    }, [])

    const getConfigList = async (data) => {
        setLoading(true)
        await MasterService.post('/sfa/user/get-config', { role_id: "", send_bm_grading: false })
            .then(function (response) {
                if (response.data.status === 200) {
                    setConfigList(response.data.data)
                }
            })
            .catch(function (response) {
            });
        setLoading(false)
    }

    useEffect(()=>{
        if(props?.userList?.user_list?.length){
            let tempList = props.userList.user_list.filter(ele => ele.is_selected);
            setUserList(tempList)
        }
    }, [props.userList])

    const handleChange = (e) => {
        setReasonForInactive(e.value)
        setInactiveReasonComment('')
        setErrorList([]);
        let tempErrors = { errors };
        delete tempErrors['reason_for_inactive']
        setErrors(tempErrors);
    }

    const validateForm = () => {
        let result = true;
        let tempErrors = { errors };
        
        if (!reason_for_inactive) {
            tempErrors.reason_for_inactive = 'Reason For Inactive is Required'
            result = false
        } if (inactive_reason_comment.trim() === "") {
            result = false
            tempErrors.inactive_reason_comment = 'Comment is Required'
        }
        setErrors(tempErrors);
        if (result) {
            if (!userList.length) {
                result = false;
                toast.error("No User Selected")
            } else {
                if (errorList.length) {
                    result = false;
                    toast.error(`User's can not be marked inactive.`)
                }
            }
        }
        return result;
    };
    const handleMarkInactiveSubmit = (e) => {
        setErrors({});
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            let params = { "user_ids": userList.map(e=>e.id), reason_for_inactive: reason_for_inactive || "", inactive_reason_comment: inactive_reason_comment || "", status: "0", active_tab: props.inactive_pending_request == 1 ? "inactive_request" : "active"}
            MasterService.post('/sfa/user/inactive-user-bulk', params)
                .then(function (response) {
                    if (response.data.status == 200) {
                        setLoading(false);
                        toast.success(response.data.message);
                    } else {
                        setLoading(false);
                        toast.error(response.data.message);
                    }
                })
                .catch(function (response) {
                    setLoading(false);
                    toast.error(response.data.message);
                });
        }
    }

    const handleChangetext = (event, type) => {
        let tempError = {...errors}
        if(type == 'inactive'){
            setInactiveReasonComment(event.target.value)
            tempError.inactive_reason_comment = ''
        }
        setErrors(tempError)
    }

    return (<>
        {loading ? <Loader /> : null}
        <div className="modal-body">
            <div className='rejection_form'>
                <form method="post" onSubmit={handleMarkInactiveSubmit} style={{ marginTop: "10px" }}>
                    <div style={{ height: "100%" }}>
                        <fieldset className="single-select">
                            {ConfigList?.inactive_reason?.length &&
                                <div className="material">
                                    <Select
                                        components={{ ValueContainer: CustomValueContainer }}
                                        id="reason_for_inactive"
                                        name="reason_for_inactive"
                                        onChange={handleChange}
                                        options={ConfigList.inactive_reason}
                                        value={ConfigList.inactive_reason.filter((e) => e.value === reason_for_inactive)}
                                        placeholder="Reason For Inactive*"
                                        className="react-select"
                                        classNamePrefix="react-select"
                                    />
                                    {
                                        (errors && errors.reason_for_inactive) ? <span className="error show">{errors.reason_for_inactive}</span> : ''
                                    }
                                </div>
                            }
                        </fieldset>
                        <fieldset className="p-lg-t p-lg-b">
                            <div class="material">
                                <textarea placeholder='' onChange={(e) => handleChangetext(e, 'inactive')} rows='6' className="form-input" name="inactive_reason_comment" value={inactive_reason_comment}></textarea>
                                {
                                    (errors && errors.inactive_reason_comment) ? <span className="error show">{errors.inactive_reason_comment}</span> : ''
                                }
                                <label data-label="Write the reasons here" className="form-label "></label>
                            </div>
                        </fieldset>
                    </div>
                    <div className='row' style={{ marginBottom: "10px", marginTop: "50px" }}>
                        <div className="col-md-12 text-center" style={{ display: "flex", justifyContent: "center" }}>
                            <button type="submit" className="btn-primary text-center">Update</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>);
}
export default MarkInactivePopup;