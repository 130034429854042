import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import MasterService from './../../services/MasterService';
import { BusinessLine, Role, TENANT, validationForFields } from './../../config/constant';
import { toast } from 'react-toastify';
import Loader from '../common/loader';
// import secureStorage from '../../config/encrypt';
const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};
const ChangeTaggingPopUp = (props) => {
    const [taggingOption, setTaggingOption] = useState([]);
    const [errors, setErrors] = useState({});
    const [tagging_id, setTaggingId] = useState(0);
    const [errorList, setErrorList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [taggingList, setTaggingList] = useState([]);
    useEffect(() => {
        setTaggingOption(props.TaggingOption || []);
    }, [props.TaggingOption])

    useEffect(()=>{
        if(props?.userList?.user_list?.length){
            let tempList = props.userList.user_list.filter(ele => ele.is_selected);
            setTaggingList(tempList)
        }
    }, [props.userList])

    const handleTaggingChange = (e) => {
        setTaggingId(e.id);
        setErrorList([]);
        let tempErrors = { errors };
        delete tempErrors['tagging_id']
        setErrors(tempErrors);
    }

    const validateForm = () => {
        let result = true;
        let tempErrors = { errors };
        
        if (!tagging_id) {
            tempErrors['tagging_id'] = 'Please select Tagging';
            result = false;
        }
        setErrors(tempErrors);
        if (result) {
            if (!taggingList.length) {
                result = false;
                toast.error("No User Selected")
            } else {
                if (errorList.length) {
                    result = false;
                    toast.error(`User's tagging can not be updated.`)
                }
            }
        }
        return result;
    };
    const handleChangeTaggingSubmit = (e) => {
        setErrors({});
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            let params = { "user_ids": taggingList.map(e=>e.id), tagging_id:  taggingOption.find(({ id }) => id === tagging_id)?.tagging_id || ""}
            MasterService.post('/sfa/user/update_tagging', params)
                .then(function (response) {
                    if (response.data.status == 200) {
                        setLoading(false);
                        toast.success(response.data.message);
                    } else {
                        setLoading(false);
                        toast.error(response.data.message);
                    }
                })
                .catch(function (response) {
                    setLoading(false);
                    toast.error(response.data.message);
                });
        }
    }

    return (<>
        {loading ? <Loader /> : null}
        <form method="post" onSubmit={handleChangeTaggingSubmit} style={{ marginTop: "10px" }}>
            <div className="row" style={{ height: "100%" }}>
                <fieldset className="single-select col-md-6">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            id="tagging_id"
                            name="tagging_id"
                            onChange={handleTaggingChange}
                            options={taggingOption}
                            value={taggingOption.filter(({ id }) => id === tagging_id)}
                            placeholder="Tagging"
                            className="react-select"
                            classNamePrefix="react-select"
                            getOptionLabel={({ tagging_name }) => tagging_name}
                            getOptionValue={({ tagging_id }) => tagging_id}
                        />
                        {
                            (errors && errors.tagging_id) ? <span className="error show">{errors.tagging_id}</span> : ''
                        }
                    </div>
                </fieldset>
            </div>
            <div className='row' style={{ marginBottom: "10px", marginTop: "50px" }}>
                <div className="col-md-12 text-center" style={{ display: "flex", justifyContent: "center" }}>
                    <button type="submit" className="btn-primary text-center">Update</button>
                </div>
            </div>
        </form>
    </>);
}
export default ChangeTaggingPopUp;